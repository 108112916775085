import { Translate } from '../components/Translate';

export const Navigation = () => {

    const {
        currentTranslations,
    } = Translate();

    const navigation = [
        { name: currentTranslations.home, href: '/home', current: false },
        { name: currentTranslations.joki, href: '/home#joki', current: false },
        { name: currentTranslations.contact, href: '/home#contact', current: false },
        { name: currentTranslations.portfolio, href: '/portfolio', current: false },
    ];

    return {
        navigation
    };
};
