import { useState } from 'react';

export const Translate = () => {

    const [isIndonesian, setIsIndonesian] = useState(true);

    const translations = {
        en: {
            home: 'Home',
            joki: 'Joki',
            contact: 'Contact',
            portfolio: 'Portfolio',
            welcomeMessage: "This website's build with ReactJS",
            connectText: 'Get connected with us on social networks :',
            portofolioText: 'Catch my portfolio :',
            contactorpricingText: 'Contact / Pricing',
            clickText: 'Click me',
            descportfolioText: 'Has 3 years of experience in Website & Mobile development, with a focus on Front-end. Experienced in using ReactJS and ExpressJS to build Web Apps, and Android Studio (Kotlin) for developing Mobile Apps',
            checkText: 'Check my latest project on Github :',
        },
        id: {
            home: 'Beranda',
            joki: 'Joki',
            contact: 'Kontak',
            portfolio: 'Portofolio',
            welcomeMessage: "Website ini dibuat dengan ReactJS",
            connectText: 'Dapatkan koneksi dengan saya di Sosial Media :',
            portofolioText: 'Temukan portofolio saya :',
            contactorpricingText: 'Kontak / Harga',
            clickText: 'Klik saya',
            descportfolioText: 'Mempunyai pengalaman selama 3 tahun dalam Website & Mobile development, terutama pada Front-end. Biasa menggunakan ReactJS x ExpressJS untuk membuat suatu Web App dan Android Studio (Kotlin) untuk membuat Mobile App',
            checkText: 'Cek projek terakhir saya di Github :',
        },
    };

    const currentTranslations = isIndonesian ? translations.id : translations.en;

    const handleLanguageSwitch = () => {
        setIsIndonesian(!isIndonesian);
    };

    return {
        isIndonesian,
        setIsIndonesian,
        currentTranslations,
        handleLanguageSwitch,
    };
};
