import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import SplashScreen from "../src/pages/Splashscreen";
import Welcome from "../src/pages/Welcome";
import Portfolio from "../src/pages/Portfolio";

function App() {
  return (
    <BrowserRouter>
      <ParallaxProvider>
        <Routes>
          <Route exact path="/" element={<SplashScreen />} />
          <Route exact path="/home" element={<Welcome />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
        </Routes>
      </ParallaxProvider>
    </BrowserRouter>
  );
}

export default App;
