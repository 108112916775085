import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/splashscreen.css'

const SplashScreen = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/home');
        }, 5000); 

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className='flex justify-center items-center min-h-screen bg-slate-700'>
            <div class="card">
                <div className='flex ms-10 justify-center items-center'>
                    <div class="loader">
                        <p className='flex ms-8 justify-center items-center'>Welcome,</p>
                        <div class="words">
                            <span class="word">Ciao</span>
                            <span class="word">Hello</span>
                            <span class="word">Hola</span>
                            <span class="word">Bonjour</span>
                            <span class="word">Nǐ hǎo</span>
                            <span class="word">Kon’nichiwa</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SplashScreen;
