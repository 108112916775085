import { useParallax } from 'react-scroll-parallax';

export const Parallax = () => {

    const smoothScroll = (target) => {
        const targetElement = document.querySelector(target);
        if (targetElement) {
            const elementPosition = targetElement.getBoundingClientRect().top;
            setTimeout(() => {
                window.scrollTo({
                    top: elementPosition,
                    behavior: 'smooth',
                });
            }, 200);
        }
    };

    const handleScroll = (event, href) => {
        event.preventDefault();
        smoothScroll(href);
    };

    const parallax = useParallax({
        speed: 10,
    });

    return {
        smoothScroll,
        handleScroll,
        parallax,
    };
};
